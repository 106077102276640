//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex';
import ProfileForm from '@/components/ProfileForm/Index';
export default {
  components: {
    ProfileForm
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    formItemLayout() {
      return {
        labelCol: { span: 4 },
        wrapperCol: { span: 18 }
      };
    },
    buttonItemLayout() {
      return {
        wrapperCol: { xs: { span: 18, offset: 4 } }
      };
    }
  },
  methods: {
    ...mapMutations('user', ['SET_COMPANY_INFO']),
    async updateProfile(value) {
      try {
        this.loading = true;
        const data = await this.$s.api.setting.updateCompanyById(this.userInfo.company_id, value);
        this.SET_COMPANY_INFO(data);
        this.$notification.success({
          message: this.$t('ChangeEmailSuccess')
        });
      } catch (error) {
        this.$notification.error({
          message: this.$t('Error')
        });
      } finally {
        this.loading = false;
      }
    }
  }
};
