var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.loading, tip: _vm.$t("Loading...") } },
        [
          _c(
            "div",
            { staticClass: "spin-content" },
            [
              _c("profile-form", {
                attrs: {
                  profile: _vm.userInfo.company,
                  formItemLayout: _vm.formItemLayout,
                  buttonItemLayout: _vm.buttonItemLayout,
                  type: "update"
                },
                on: { updateProfile: _vm.updateProfile }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }